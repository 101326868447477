@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

.app {
    text-align: center;
    font-family: 'Lora';
}

.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-box {
    display: none;
}

.center-box, .right-box {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;
}

.right-box {
    position: relative;
    overflow: hidden;
}

.landing-link {
    text-decoration: none;
    color: #000000;
}

.nav-links-container {
    display: none;
}

.nav-link {
    text-decoration: none;
    display: block;
    padding: 15px;
    color: #000000;
    width: 100vw;
    border-top: 1px solid black;
}

.last {
    border-bottom: 1px solid black;
    padding-bottom: 20px;
}

.hamburger {
    border: none;
    background-color: transparent;
    font-size: 30px;
}

.active {
    display: block;
    position: fixed;
    right: 0px;
}

.active .nav-link {
    background-color: #ffffffd3;
}

.footer-img {
    width: 100vw;
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .left-box {
        display: inline-block;
        margin-left: 15px;
    }

    .app-footer {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .footer-img {
        display: inline-block;
        width: 20vw;
    }

    .footer-address {
        display: inline-block;
        text-align: right;
    }
}