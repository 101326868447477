.testimonials-header {
    margin-left: 5vw;
    margin-right: 5vw;
}

.testimonials-grid {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.testimonials-item {
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: 14px;
}

.testimonials-item h2 {
    font-size: 16px;
}

.test-img {
    display: none;
}

@media screen and (min-width: 768px) {
    
    .test-stripe-bottom, .test-stripe-top {
        position: absolute;
    }

    .test-img {
        display: inline-block;
        border-radius: 100%;
        width: 25vw;
        height: 25vw;
    }

    .item-text {
        display: inline-block;
        width: 35vw;
    }

    .testimonials-item {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-size: 20px;
    }

    .testimonials-item h2 {
        font-size: 24px;
    }

    .test-flag {
        width: 15vw;
    }

    .test-stripe-container {
        position: relative;
        z-index: -1;
    }

    .red.test-stripe-bottom {
        width: 100vw;
        height: 20px;
        top: 90px;
    }

    .blue.test-stripe-bottom {
        width: 100vw;
        height: 20px;
        top: 120px;
    }

    .blue.test-stripe-top {
        width: 100vw;
        height: 20px;
    }

    .red.test-stripe-top {
        width: 100vw;
        height: 20px;
        top: 40px;
        left: 0;
    }
}