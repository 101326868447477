@import url(https://fonts.googleapis.com/css2?family=Lora&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
    text-align: center;
    font-family: 'Lora';
}

.topbar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.left-box {
    display: none;
}

.center-box, .right-box {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;
}

.right-box {
    position: relative;
    overflow: hidden;
}

.landing-link {
    text-decoration: none;
    color: #000000;
}

.nav-links-container {
    display: none;
}

.nav-link {
    text-decoration: none;
    display: block;
    padding: 15px;
    color: #000000;
    width: 100vw;
    border-top: 1px solid black;
}

.last {
    border-bottom: 1px solid black;
    padding-bottom: 20px;
}

.hamburger {
    border: none;
    background-color: transparent;
    font-size: 30px;
}

.active {
    display: block;
    position: fixed;
    right: 0px;
}

.active .nav-link {
    background-color: #ffffffd3;
}

.footer-img {
    width: 100vw;
    margin-top: 10px;
}

@media screen and (min-width: 768px) {
    .left-box {
        display: inline-block;
        margin-left: 15px;
    }

    .app-footer {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        -webkit-align-items: center;
                align-items: center;
    }

    .footer-img {
        display: inline-block;
        width: 20vw;
    }

    .footer-address {
        display: inline-block;
        text-align: right;
    }
}
.top-blue, .stripe-container, .bottom-red, .bottom-white, .bottom-blue {
    display: none;
}

.home-img {
    border: 1px solid black;
    width: 85vw;
    height: 85vw;
    margin-top: 25px;
}

.intro {
    margin-left: 30px;
    margin-right: 30px;
}

.hello-button, .call-button {
    background-color: transparent;
    border: 1px solid black;
    padding-top: 15px;
    padding-bottom: 15px;
}

.listings-box {
    border: none;
    max-width: 675px;
}

button {
    font-family: 'Lora';
}

.hello-button {
    padding-left: 30px;
    padding-right: 30px;
}

.home-main {
    margin-top: 45px;
    margin-left: 30px;
    margin-right: 30px;
}

.call-button {
    width: 50vw;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 20px;
}

.flag-img {
    width: 40vw;
    margin-top: 30px;
    margin-bottom: 30px;
}

.footer-about, .footer-testimonials, .footer-calculator {
    display: block;
    width: 100%;
    height: 25vh;
    text-decoration: none;
    color: #ffffff;
    padding-top: 20px;
}

.black {
    color: #000000;
}

.footer-about {
    background-image: url(/static/media/boot.164da785.jpg);
    background-repeat: none;
    background-position: bottom;
}

.footer-testimonials {
    background-image: url(/static/media/skyline.32f8e3f4.jpeg);
    background-size: cover
}

.footer-calculator {
    background-image: url(/static/media/carnival.406349ab.jpg);
    background-repeat: none;
    background-position: center;
}

.red, .white, .blue {
    width: 100%;
    height: 15px;
}

.red {
    background-color: #BF0A30;
}

.blue {
    background-color: #012868;
}

@media screen and (min-width: 768px) {

    .top-blue {
        display: block;
        width: 100%;
        height: 40px;
        background-color: #012868;
    }

    .stripe-container {
        display: block;
        position: relative;
        z-index: -1;
    }

    .vert-red {
        position: absolute;
        width: 40px;
        height: 170vh;
        left: 0px;
        background-color: #BF0A30;
    }

    .vert-white {
        position: absolute;
        width: 40px;
        height: 170vh;
        left: 40px;
    }

    .vert-blue {
        position: absolute;
        width: 40px;
        height: 170vh;
        left: 80px;
        background-color: #012868;
    }

    .home-header {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        -webkit-align-items: center;
                align-items: center;
    }

    .home-img {
        display: inline-block;
        width: 30vw;
        height: 30vw;
        margin-left: 20vw;
    }

    .intro {
        display: inline-block;
        width: 30vw;
        text-align: left;
        margin-right: 10vw;
    }

    .listings-box {
        width: 60vw;
        height: 40vh;
    }

    .hello-button {
        border: 3px solid #012868;
        transition: all 0.5s ease;
    }

    .call-button {
        width: 200px;
        margin: 0px;
        border: 3px solid #012868;
        transition: all 0.5s ease;
    }

    .call-button:hover, .hello-button:hover {
        background-color: #012868;
        color: #ffffff;
    }

    .red-border, .white-border {
        display: inline-block;
    }

    .call {
        margin-top: 20px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .red-border {
        border: 3px solid #BF0A30;
    }

    .white-border {
        border: 3px solid #ffffff;
    }

    .flag-img {
        width: 25vw;
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .footer-nav {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
    }

    .footer-about, .footer-testimonials, .footer-calculator {
        height: 350px;
        background-color: #000000;
        transition: all 0.5s ease;
    }

    .footer-about:hover, .footer-testimonials:hover, .footer-calculator:hover {
        opacity: 0.5;
    }

    .red.home, .white.home, .blue.home {
        width: 30px;
        height: 370px;
    }

    .bottom-blue {
        display: block;
        background-color: #012868;
        height: 40px;
        width: 100%;
    }

    .bottom-white {
        display: block;
        height: 40px;
        width: 100%;
    }

    .bottom-red {
        display: block;
        background-color: #BF0A30;
        height: 40px;
        width: 100%;
    }
}
.about-img {
    width: 90vw;
    height: auto;
    margin-bottom: 15px;
}

.todo-grid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: left;
}

.todo-item {
    height: 30vh;
    color: #ffffff;
    padding-left: 5vw;
    padding-right: 5vw;
}

.right {
    text-align: right;
}

.item-one {
    background-image: url(https://www.conserve-energy-future.com/wp-content/uploads/2013/05/biofuel-tanks-filled-biodiesel.jpg);
    background-size: cover;
}

.item-two {
    background-image: url(https://www.outsideonline.com/sites/default/files/styles/full-page/public/2018/07/09/campfire-backcountry-pot_h.jpg?itok=Qnx1rsSM);
    background-size: cover;
}

.item-three {
    background-image: url(https://i.pinimg.com/originals/e5/d4/bd/e5d4bdda010926b4aea3f447835c3bc9.jpg);
    background-size: cover;
}

.item-four {
    background-image: url(https://greatamericanwest.co/wp-content/uploads/Cheyenne-Frontier-Days.jpg);
    background-size: cover;
}

.item-five {
    background-image: url(https://www.outsideonline.com/sites/default/files/styles/full-page/public/2020/03/12/gem-lake-and-longs-peak-sunset_h.jpg?itok=uQRzVpNy);
    background-size: cover;
}

.img-and-text {
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and (min-width: 768px) {
    .todo-grid {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        text-align: center;
    }

    .right {
        text-align: center;
    }

    .red {
        width: 100vw;
        height: 4vh;
    }

    .blue {
        width: 100vw;
        height: 4vh;
        margin-bottom: 20px;
    }

    .about-img {
        width: 30vw;
    }

    .img-and-text > div {
        width: 60vw;
        text-align: left;
    }

    .img-and-text {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
    }
}
.testimonials-header {
    margin-left: 5vw;
    margin-right: 5vw;
}

.testimonials-grid {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    text-align: left;
}

.testimonials-item {
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: 14px;
}

.testimonials-item h2 {
    font-size: 16px;
}

.test-img {
    display: none;
}

@media screen and (min-width: 768px) {
    
    .test-stripe-bottom, .test-stripe-top {
        position: absolute;
    }

    .test-img {
        display: inline-block;
        border-radius: 100%;
        width: 25vw;
        height: 25vw;
    }

    .item-text {
        display: inline-block;
        width: 35vw;
    }

    .testimonials-item {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        -webkit-align-items: center;
                align-items: center;
        font-size: 20px;
    }

    .testimonials-item h2 {
        font-size: 24px;
    }

    .test-flag {
        width: 15vw;
    }

    .test-stripe-container {
        position: relative;
        z-index: -1;
    }

    .red.test-stripe-bottom {
        width: 100vw;
        height: 20px;
        top: 90px;
    }

    .blue.test-stripe-bottom {
        width: 100vw;
        height: 20px;
        top: 120px;
    }

    .blue.test-stripe-top {
        width: 100vw;
        height: 20px;
    }

    .red.test-stripe-top {
        width: 100vw;
        height: 20px;
        top: 40px;
        left: 0;
    }
}
.iframe-calculator {
    width: 100%;
    height: 100vh;
    border: none;
}

@media screen and (min-width: 768px) {
    .calc-stripe-top, .calc-stripe-bottom {
        position: absolute;
    }

    .calc-stripe-container {
        position: relative;
    }

    .iframe-calculator {
        width: 70vw;
        height: 120vh;
    }

    .blue.calc-stripe-top {
        height: 121vh;
        width: 30px;
        top: 0vh;
        left: 30px
    }

    .red.calc-stripe-top {
        height: 121vh;
        width: 30px;
        left: 90px;
    }

    .blue.calc-stripe-bottom {
        height: 121vh;
        width: 30px;
        bottom: 0vh;
        right: 30px
    }

    .red.calc-stripe-bottom {
        height: 121vh;
        width: 30px;
        right: 90px;
        bottom: 0vh;
    }
}
.not-found-button {
    background-color: transparent;
    border: 1px solid black;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 55vw;
    margin-bottom: 25px;
}

.nf-flag-img {
    width: 40vw;
    margin-top: 25px;
    margin-bottom: 25px;
}

@media screen and (min-width: 768px) {
    .not-found-button {
        border: 3px solid #012868;
        width: 200px;
        margin-bottom: 0px;
        transition: all 0.5s ease;
    }

    .not-found-button:hover {
        background-color: #012868;
        color: #ffffff;
    }

    .nf.red-border {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 40px;
    }

    .nf-stripe-container {
        position: relative;
        margin-bottom: 210px;
    }

    .red.nf-stripe-bottom {
        position: absolute;
        width: 100vw;
        height: 30px;
        top: 120px;
    }

    .blue.nf-stripe-bottom {
        position: absolute;
        width: 100vw;
        height: 30px;
        top: 60px;
    }

    .white.nf-stripe-bottom {
        position: absolute;
        width: 100vw;
        height: 30px;
        top: 90px;
        margin: 0;
    }

    .nf-flag-img {
        width: 20vw;
        margin-top: 0;
        margin-bottom: 25px;
    }
}
